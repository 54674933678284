import { Navbar } from "../components/NavBar";
import { SongList } from "../components/SongList";

export const SongListPage = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col mt-5 text-center">
          <h1>Song List</h1>
          {/* <p>
            Here is a sample of some of the songs I like to play. Usually these
            will be mixed and matched to suit your guests, but if you have any
            special requests, just let me know and I’ll be happy to learn them!
          </p> */}
        </div>
      </div>
      <SongList />
    </div>
  );
};
