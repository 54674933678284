import { PackageCard } from "../components/PackageCard";
import ceremonyImage from "../images/packagesPage/ceremony.jpg";
import eveningImage from "../images/packagesPage/evening.jpg";
import receptionImage from "../images/packagesPage/reception.jpg";
import mealImage from "../images/packagesPage/meal.jpg";
import { Navbar } from "../components/NavBar";

export const PackagesPage = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col text-center mt-5 mb-4">
          <h1 className="">Packages</h1>
          <p>
            Here's a selection of popular packages that couples often ask for.
            However, if you have something else in mind, or would like to book
            multiple packages, just let me know! I'll be happy to create a
            custom package that fits in with your day.
          </p>
        </div>
      </div>
      <div className="row g-3 mb-5">
        <PackageCard
          icon="💖"
          title="Ceremony"
          image={ceremonyImage}
          description="Live music during the main event"
          details="Your specific Aisle song
            +
            Music as the guests arrive and exit the ceremony
            +
            Music during the signing of the register"
          size="large"
        />
        <PackageCard
          icon="🍸"
          title="Reception"
          image={receptionImage}
          description="Light, upbeat music as your guests enjoy their drinks"
          details="2x45m sets of laidback, acoustic music
            +
            Background playlist between sets"
          size="small"
        />
        <PackageCard
          icon="🍽️"
          title="Meal"
          image={mealImage}
          description="Music during the wedding breakfast"
          details="2x45m sets of laidback, acoustic music
            +
            Background playlist between sets"
          size="small"
        />
        <PackageCard
          icon="💃"
          title="Evening"
          image={eveningImage}
          description="Get the party started with the evening package"
          details="Your first dance song played live
            +
            2x45m sets of upbeat music
            +
            Background playlist between sets"
          size="small"
        />
      </div>
    </div>
  );
};
