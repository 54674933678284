import { Review } from "../appStateTypes";
import wards from "../images/reviewPhotos/wards.jpg";
import amyAndAlex from "../images/reviewPhotos/amyAndAlex.jpg";
import crombies from "../images/reviewPhotos/crombies.jpg";
import lukeBeckham from "../images/reviewPhotos/lukeBeckham.jpg";
import alans from "../images/reviewPhotos/alans.jpg";
import noraChallis from "../images/reviewPhotos/noraChallis.jpg";
import gregHarman from "../images/reviewPhotos/gregHarman.jpg";
import sophieVaughan from "../images/reviewPhotos/sophieVaughan.jpg";
import thompsons from "../images/reviewPhotos/thompsons.jpg";
import worthingtons from "../images/reviewPhotos/worthingtons.jpg";
import wilkinsons from "../images/reviewPhotos/wilkinsons.jpg";
import messners from "../images/reviewPhotos/messners.jpg";
import owsts from "../images/reviewPhotos/owsts.jpg";
import officers from "../images/reviewPhotos/officers.jpg";
import knowless from "../images/reviewPhotos/knowless.jpg";
import smiths from "../images/reviewPhotos/smiths.jpg";
import oslers from "../images/reviewPhotos/oslers.jpg";
import rowlands from "../images/reviewPhotos/rowlands.jpg";
import williams from "../images/reviewPhotos/williams.jpg";
import fairweathers from "../images/reviewPhotos/fairweathers.jpg";
import milsoms from "../images/reviewPhotos/milsoms.jpg";
import lamberts from "../images/reviewPhotos/lamberts.jpg";
import hunters from "../images/reviewPhotos/hunters.jpg";
import jacksons from "../images/reviewPhotos/jacksons.jpg";
import lauraDives from "../images/reviewPhotos/lauraDives.jpg";
import jazlinCruickshank from "../images/reviewPhotos/jazlinCruickshank.jpg";
import martinezs from "../images/reviewPhotos/martinezs.jpg";
import russells from "../images/reviewPhotos/russells.jpg";
import ashleaHutchinson from "../images/reviewPhotos/ashleaHutchinson.jpg";
import mcCarters from "../images/reviewPhotos/mcCarters.jpg";
import hollands from "../images/reviewPhotos/hollands.jpg";
import kieranRichardson from "../images/reviewPhotos/kieranRichardson.jpg";
import harryBates from "../images/reviewPhotos/harryBates.jpg";
import maltass from "../images/reviewPhotos/maltass.jpg";
import horans from "../images/reviewPhotos/horans.jpg";
import perkinsJones from "../images/reviewPhotos/perkinsJones.jpg";
import McManus from "../images/reviewPhotos/McManus.jpg";

export const reviews: Review[] = [
  {
    author: "Mr & Mrs Ward",
    body: "Punctual, professional, perfect! What more can I say. Sam performed for us on our wedding day and he was fantastic. Really made a great atmosphere.  Would definitely use again in the future.",
    imgSrc: wards,
    date: "01-09-2023",
  },
  {
    author: "Amy & Alex",
    body: "Had a great time watching Sam play for my wedding, fantastic and lovely too! Definitely recommend to anyone 🙂",
    imgSrc: amyAndAlex,
    date: "18-08-2023",
  },
  {
    author: "Mr and Mrs Crombie",
    body: "Thankyou so much to Sam he was fantastic at our wedding at the beginning of sep he was friendly and played a fantastic mix of music. Also learning a song choice that was wanted for an isle song. Thanks so much. Everyone was commenting on him and how fabulous he was. I would highly recommend and will be passing on recommendations. Many thanks",
    imgSrc: crombies,
    date: "17-09-2022",
  },
  {
    author: "Luke Beckham",
    body: "just had Sam performing for our work party. he was phenomenal! got everybody up on the dance floor, gave an amazing performance and left everyone wanting more. could not thank Sam enough and would highly recommend for any occasion. thank you so much for making our party that extra special!",
    imgSrc: lukeBeckham,
    date: "23-03-2020",
  },
  {
    author: "Mr and Mrs Alan",
    body: "Highly recommend. Sam recently sang at our wedding, singing in the church while everyone was getting seated. it created a great atmosphere. He even learnt how to sing the hymns we had chosen for the church and he sang them amazingly.",
    imgSrc: alans,
    date: "17-10-2019",
  },
  {
    author: "Nora J Challis",
    body: "Sam's one of the most amazing musicians I've come across. He knows exactly what he's doing and makes everyone smile around him with his music. He's performed a few times in the bar I work and no ones ever been disappointed. If you haven't heard him yet, you've got to go to one of his gigs!!",
    imgSrc: noraChallis,
    date: "11-11-2018",
  },
  {
    author: "Greg Harman",
    body: "Sam is very talented and a brilliant performer. He opened a fundraiser night for the charity I work for and the feedback was excellent. I would recommend him for any event, a really nice guy too! 5 stars",
    imgSrc: gregHarman,
    date: "16-02-2018",
  },
  {
    author: "Sophie Vaughan",
    body: "Sam!! A massive thank you to you for playing at our intimate wedding reception on Saturday. You were absolutely amazing, from the moment I contacted you the communication was great and made life very easy! So many compliments on the day and after the event also, we couldn’t be happier to have found you and thank you for a fab couple of hours of your amazing talent and presence!",
    imgSrc: sophieVaughan,
    date: "19-06-2023",
  },
  {
    author: "Mr & Mrs Thompson",
    body: "Sam sang for our wedding earlier this month. He was absolutely amazing. We had all of our guests coming up to us saying just how great he is and how beautiful it was having him playing in the ceremony. I had requested a specific song for the aisle song that was not on his repertoire and without hesitation Sam said he would be able to learn it. He sent through a couple recordings so we could hear what he was working on and it was just stunning. Even though I was running a little late Sam effortlessly continued to play whilst all our guests were seated waiting for the ceremony to start. Then although the heavens opened during our welcome drinks Sam quickly got set up again in another area of the venue and continued to play. We couldn't have asked for more from Sam.",
    imgSrc: thompsons,
    date: "19-05-2023",
  },
  {
    author: "Mr & Mrs Worthington",
    body: "Thank you for signing at our wedding ceremony Sam, you were amazing and so many people commented on how great your voice is! We would 100% recommend Sam for anyone thinking of a singer for their wedding!",
    imgSrc: worthingtons,
    date: "26-06-2023",
  },
  {
    author: "Mr & Mrs Wilkinson",
    body: "Sam performed at our wedding on 1/4/23 at Thirsk Lodge Barns, before/during the ceremony (including aisle music) and then afterwards during the drinks reception. Communication beforehand was brilliant and his flexibility to allow us to select/adapt songs (from his impressive set list) was amazing - music is so important to us and he delivered 100%, it couldn’t have been better. Sam played an aisle song that had a very special meaning to us, and it was BEAUTIFUL - most of the guests were emotional during the intro!! Thank you Sam sooo much for making this moment even more special - it was exceptional. Throughout the day, Sam was extremely professional and his presence felt so natural and fitting with the setting - we wouldn’t hesitate to 100% recommend him to anyone for their special day, or for any event for that matter! Thank you Sam 🫶🏻 Jen & Jack x",
    imgSrc: wilkinsons,
    date: "23-04-2023",
  },
  {
    author: "Mr & Mrs Messner",
    body: "We couldn’t recommend Sam highly enough, he was simply brilliant from the first message to making our wedding just perfect with his music. We had so many compliments on his behalf. Thank Sam, Seven Wonders will alway hold a special place in our hearts!",
    imgSrc: messners,
    date: "23-08-2022",
  },
  {
    author: "Mr & Mrs Owst",
    body: "Sam was absolutely amazing at our chilled out wedding. He helped make the day so special to us and all our guests raved about him, including us! 10/10! Thank you so much Sam, you were a huge part in making our day so special ☺️ Amy & Ben x x",
    imgSrc: owsts,
    date: "17-08-2022",
  },
  {
    author: "Mr & Mrs Officer",
    body: "Amazing service, such a polite and friendly guy and very talented and professional. All our guests commented on how great you were. Thank you Sam 🌟🌟🌟🌟🌟",
    imgSrc: officers,
    date: "05-06-2022",
  },
  {
    author: "Mr & Mrs Knowles",
    body: "Sam was fantastic, his service was great and so easy going! Just wanted to say a huge thank you, you were so perfect for our Wedding day, everyone loved you and the atmosphere you created!!! 10/10 would recommend, thank you again Sam ⭐️",
    imgSrc: knowless,
    date: "15-06-2022",
  },
  {
    author: "Mr & Mrs Smith",
    body: "We just want to say thankyou so much for yesterday. You were brilliant. You created the perfect atmosphere during your time on our big day. The guests were all really impressed. Again, thankyou. Tanya & Steve",
    imgSrc: smiths,
    date: "11-06-2022",
  },
  {
    author: "Mr & Mrs Osler",
    body: "Sam sang at our wedding in October and he was amazing!! Thank you so so much for performing at our wedding ceremony and drinks reception Sam! We had so many compliments on your performance and it meant the world to us that you played Holocene as I walked down the aisle! It was so beautiful and I still get goosebumps when I hear it now!",
    imgSrc: oslers,
    date: "06-04-2023",
  },
  {
    author: "Mr & Mrs Rowlands",
    body: "Sam played at our Welcome drinks reception at Cave Castle on the 28.05.22. He was absolutely amazing. Really talented. Lots of compliments. Thanks Sam.",
    imgSrc: rowlands,
    date: "28-05-2022",
  },
  {
    author: "Mr & Mrs Bates",
    body: "Hi Sam just wanted to thank you so much. You made our day special. What an amazing voice you have. All my guest commented on how lovely your voice was and your choice of songs was so laid back and chilled. Brilliant talent. Thank you. David & Dayle",
    imgSrc: "",
    date: "10-05-2022",
  },
  {
    author: "Mr & Mrs Williams",
    body: "Sam played at the evening celebration of our wedding at Bagden Hall. Sam is a lovely warm communicative young man, super talented a great sound and really engages well with the party, and of all ages, many comments preferring Sam to the dj.Don’t doubt book him!! Thanks so much Sam",
    imgSrc: williams,
    date: "29-01-2022",
  },
  {
    author: "Mr & Mrs Fairweather",
    body: "Sam was absolutely amazing at our wedding in August. He sang at our arrival drinks which created a lovely atmosphere and then on the evening. So many people commented how brilliant he was and how much they enjoyed his music! Thank you so much once again Sam you are fab and we will definitely be recommending you to everyone! 🎶🎵",
    imgSrc: fairweathers,
    date: "06-10-2021",
  },
  {
    author: "Mr & Mrs Milsom",
    body: "Sam provided the perfect relaxed atmosphere for our Wedding reception last weekend. Sam's equipment is top-notch - his set sounded fantastic at our outdoor venue - just like his promo videos. Thank you for a great performance that really set the tone for our day! 😀",
    imgSrc: milsoms,
    date: "12-09-2021",
  },
  {
    author: "Mr & Mrs Lambert",
    body: "We booked Sam for our wedding recently. Rocked up early to get set up, great repertoire, awesome performer, fantastic entertainment and a cut above for customer service. Plenty of guests have commented since on how great he was. We would highly recommend Sam for any event you have coming up! Thank you Sam!",
    imgSrc: lamberts,
    date: "23-08-2021",
  },
  {
    author: "Mr & Mrs Hunter",
    body: "Sam played during the drinks reception at our wedding and was brilliant from start to finish. He was helpful and always quick to respond to emails in the lead up to the day. We had planned for him to play outside but when the British weather got in the way of that Sam was happy to quickly move his equipment indoors. So many guests have said how much they enjoyed Sam’s music and it was the perfect soundtrack to the afternoon. Thanks for being part of the day and we would 100% recommend Sam for any occasion!",
    imgSrc: hunters,
    date: "01-09-2021",
  },
  {
    author: "Jackson",
    body: "We decided last minute that we wanted a live performer at our Wedding... Sam came to the rescue! He was absolutely fantastic ❤️ He sang a wide range of songs and got us up dancing. He's a very approachable, polite man too! We would definatly recommend him too anyone! Thanks again Sam you were amazing! Sarah and Chris",
    imgSrc: jacksons,
    date: "08-08-2021",
  },
  {
    author: "Laura Dives",
    body: "Sam sang at my birthday garden party and was absolutely amazing! You have one superb voice Sam! Thank you for some fabulous hours of singing! I would highly reccommend to anyone looking for a unique talented singer!",
    imgSrc: lauraDives,
    date: "04-08-2021",
  },
  {
    author: "Mr & Mrs Martinez",
    body: "If you're reading this, book Sam Dawson! He was fantastic in every aspect from his performances down to his manner. We booked Sam for our Ceremony, Drinks Reception and our Wedding Breakfast. Great value for money and brilliant entertainment throughout. He really helped make our big day that extra bit special. We have already decided to book him again when we have a big party once restrictions relax further. 10/10",
    imgSrc: martinezs,
    date: "05-06-2021",
  },
  {
    author: "David Lambert",
    body: "Sam travelled all the way to Bowness on Windermere to play at the wedding of our son & daughter in law. He’s a great entertainer and talented musician with a huge repertoire of material spanning several generations - something for everyone - even a couple of Jungle Book favourites for the kids! And all performed to perfection; a true professional. The hotel didn’t allow amplification but, after some boisterous, noisy ‘party games’ his voice and guitar cut through and everybody had a great time. Recorded music is fine but nothing quite beats a good live performance - and Sam’s was exceptional. Book him; you won’t be disappointed 🎤🎵🎸👍",
    imgSrc: "",
    date: "09-08-2021",
  },
  {
    author: "Mr and Mrs Russell",
    body: "Sam was brilliant. Set up with no issues and played his set which fitted the wedding brilliantly. We spoke inbetween sets and he was a lovely and polite person. He learnt our first dance song and delivered it perfectly. Thankyou Sam for helping make our day special",
    imgSrc: russells,
    date: "03-06-2021",
  },
  {
    author: "Ashlea Hutchinson",
    body: "Hired Sam to play in our garden for our sons graduation party, he had us all singing and dancing all night Fantastic singer guitarist and will definitely have him back in the future 😀😀",
    imgSrc: ashleaHutchinson,
    date: "01-08-2021",
  },
  {
    author: "Mr and Mrs McCarter",
    body: "Sam played at our wedding and was brilliant. He played a good mix of songs that we chose from his repertoire. Everyone at the wedding enjoyed his performance.",
    imgSrc: mcCarters,
    date: "27-06-2021",
  },
  {
    author: "Jazlin Cruickshank",
    body: "Sam was absolutely fantastic, playing at a garden birthday party for us. Great music whilst the party got started and by the end of his set had everyone up and dancing with some great sing along numbers. I would not hesitate for a moment to recommend Sam. A really friendly chap too, and great communication from the point of booking up until the day. Thanks Sam, great great work!!",
    imgSrc: jazlinCruickshank,
    date: "05-06-2021",
  },
  {
    author: "Mr and Mrs Holland",
    body: "Thank you so much Sam for singing at our wedding garden party - everyone loved listening to you. I would 10/10 recommend Sam to anyone who is looking for a singer for any event",
    imgSrc: hollands,
    date: "25-10-2019",
  },
  {
    author: "Kieran Richardson",
    body: "Sam was busking outside my work today, he made the day alot better, so we muted our sound system and told him to turn it up. Fantastic musician, didn't play the same song twice in 3hrs, such a range of different styles from Oasis to King of the Swing from the Jungle book. Such a talented man.",
    imgSrc: kieranRichardson,
    date: "19-12-2018",
  },
  {
    author: "Harry Bates",
    body: "Came and performed at Sleepers Bar Beverley. Came in early, got set up and even started early. Great voice, great skills on the guitar and certainly got the crowd hooked. Would 100% recommend him.",
    imgSrc: harryBates,
    date: "13-11-2018",
  },
  {
    author: "Mr and Mrs Maltas",
    body: "Sam recently played for us during our reception drinks at our Wedding at Rise Hall and was absolutely fantastic. He played a real mix of material appropriate for all guests and created a fabulous atmosphere and enetertained guests during our photos.  He was a pleasure to speak with and I was able to leave him to organise his own set up etc and was able to simply arrive and hear him on the day which was a blessing in itself and one less thing to worry about. Highly recommended",
    imgSrc: maltass,
    date: "30-10-2018",
  },
  {
    author: "Mr and Mrs Horan",
    body: "I recently booked Sam to play on my wedding day. As everyone sat in the sunshine listening, he created the perfect atmosphere. Not only did he play 2 sets - early and late afternoon (so sorry Sam!) he also learnt a song of my choosing to play me down the aisle which he played/sang AMAZINGLY! I can’t thank Sam enough, he is such a lovely person, I wish I had more time to chat to him on the day! Thank you, thank you, thank you!!",
    imgSrc: horans,
    date: "05-07-2018",
  },
  {
    author: "Mr and Mrs Perkins-Jones",
    body: "Thank you Sam for providing an excellent performance during our drinks reception.. yesterday. fantastic choice of songs sounded great and such a lovely guy too! Thank you again Mr & Mrs Perkins-Jones",
    imgSrc: perkinsJones,
    date: "25-10-2019",
  },
  {
    author: "Mr and Mrs McManus",
    body: "I can not speak highly enough of Sam as an artist and as a person. Sam recently performed at our wedding and myself and my guests where blown away. His voice and skills are incredible, playing a wide range of songs which had everybody up and dancing. I requested a few alternative songs which where close to my heart, Sam was happy to do this for me and played them beautifully, which truly meant a lot to me on my special day.I can not recommend Sam enough to anybody planning a wedding or party. He is a friendly, professional and fantastic artist with an incredible voice!Thank you so much Sam for making our day truly special!",
    imgSrc: McManus,
    date: "02-05-2018",
  },
];
