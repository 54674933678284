import { useEffect } from "react";
import { reviews } from "../constants/reviews";
import { Helmet } from "react-helmet";
import imagesLoaded from "imagesloaded";
import Masonry from "masonry-layout";

export const ReviewsPage = () => {
  useEffect(() => {
    // Function to initialize Masonry layout
    const initMasonry = () => {
      const grid = document.querySelector(".masonry-grid");
      if (grid) {
        new Masonry(grid, {
          itemSelector: ".grid-item",
          percentPosition: true,
        });
      }
    };

    // Wait for all images to load
    const imagesLoadedHandler = imagesLoaded(".masonry-grid");
    imagesLoadedHandler.on("always", () => {
      // Once all images are loaded, initialize the Masonry layout
      initMasonry();
    });

    // Clean up event listeners when the component unmounts
    return () => {
      imagesLoadedHandler.off("always", () => {});
    };
  }, []);
  return (
    <>
      <Helmet>
        <script
          src="https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js"
          integrity="sha384-GNFwBvfVxBkLMJpYMOABq3c+d3KnQxudP/mGPkzpZSTYykLBNsZEnG2D9G/X/+7D"
          crossOrigin="anonymous"
          async
        ></script>
      </Helmet>
      <div className="container">
        <div className="mt-5 mb-4 text-center">
          <h1 className="">Reviews</h1>
          <p>A small sample of happy customers!</p>
        </div>

        <div className="row mb-5 masonry-grid">
          {reviews.map((review, index) => (
            <div className="col-12 col-md-6 col-lg-4 grid-item" key={index}>
              <div className="card mt-4 shadow zoom">
                <img src={review.imgSrc} className="card-img-top" />
                <div className="card-body">
                  <figure>
                    <blockquote className="blockquote fst-italic">
                      <p>"{review.body}"</p>
                    </blockquote>
                    <figcaption className="blockquote-footer">
                      {review.author} - {review.date}
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
