import { API } from "aws-amplify";
import { FormEvent, useState } from "react";
import DatePicker from "react-datepicker";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";

// import "react-datepicker/dist/react-datepicker.css";
import { socialUrls } from "../appConstants";

export const ContactPage = () => {
  const [eventDate, setEventDate] = useState<Date | null>(null);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [venue, setVenue] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [captchaError, setCaptchaError] = useState<boolean>(false);

  // const captchaRef = useRef(null);
  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const resetForm = () => {
    setEventDate(null);
    setName("");
    setEmail("");
    setVenue("");
    setMessage("");
  };

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const token = recaptchaRef.current?.getValue();
    recaptchaRef.current?.reset();

    const apiName = "samdawsonmusicAPI";
    const path = "/submitContactForm";
    const myInit = {
      headers: {},
      body: {
        name: name,
        email: email,
        eventDate: eventDate?.toDateString(),
        venue: venue,
        message: message,
        recaptchaToken: token,
      },
    };

    await API.post(apiName, path, myInit).then((response) => {
      setFormSubmitted(true);
      resetForm();
    });
  }

  if (!formSubmitted)
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col">
            <h1 className="text-center">Contact</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className=" mb-3">
              <p className="text-center">
                Ready to discuss your event? Use the form below to send me a
                message and I will get back to you ASAP.
              </p>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="mb-3">
                <label htmlFor="inputName" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  data-testid="inputName"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="inputEmail" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail"
                  data-testid="inputEmail"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="inputVenue" className="form-label">
                  Venue
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputVenue"
                  data-testid="inputVenue"
                  value={venue}
                  onChange={(e) => {
                    setVenue(e.target.value);
                  }}
                  required
                />
              </div>
              <label htmlFor="inputDate" className="form-label">
                Date{" "}
              </label>
              <DatePicker
                selected={eventDate}
                onChange={(eventDate) => setEventDate(eventDate)}
                className="form-control mb-3 input"
                id="inputDate"
                data-testid="inputDate"
                required
                dateFormat="eeee do MMMM yyyy"
              />
              <div className="mb-3">
                <label htmlFor="inputMessage" className="form-label">
                  Message
                </label>
                <textarea
                  className="form-control"
                  id="inputMessage"
                  data-testid="inputMessage"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="d-flex justify-content-center mb-3">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                  ref={recaptchaRef}
                  size="normal"
                  className={captchaError ? "border border-danger" : ""}
                />
              </div>
              <div className="d-grid gap-2">
                <button type="submit" className="btn btn-primary mb-3">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col text-center">
            <h1 className="text-center text-success">Thank you!</h1>
            <p>
              I have received your enquiry and will get back to you ASAP.
              <br></br> In the meantime, why not follow me on social media and
              see what I've been up to?
            </p>
            <div className="mb-5">
              <a
                href={socialUrls.FACEBOOK}
                className="me-4 link-secondary"
                target="_blank"
              >
                <i className="bi bi-facebook"></i>
              </a>
              <a
                href={socialUrls.INSTAGRAM}
                className="me-4 link-secondary"
                target="_blank"
              >
                <i className="bi bi-instagram"></i>
              </a>
              <a
                href={socialUrls.YOUTUBE}
                className="me-4 link-secondary"
                target="_blank"
              >
                <i className="bi bi-youtube"></i>
              </a>
              <a
                href={socialUrls.TIKTOK}
                className="me-4 link-secondary"
                target="_blank"
              >
                <i className="bi bi-tiktok"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
};
