import { useEffect, useState } from "react";
import { playlistItem, playlistItemList } from "../appStateTypes";
import { emptyPlaylistItemList } from "../sampleEmptyStates";
import { getVideos } from "../api/getVideos";

export const VideosPage = () => {
  const [playlist, setPlaylist] = useState<playlistItemList>(
    emptyPlaylistItemList
  );
  const [selectedVideo, setSelectedVideo] = useState<playlistItem>();

  useEffect(() => {
    getVideos().then((response: any) => {
      setPlaylist(response);
      setSelectedVideo(response.items[0]);
    });
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="mt-5 mb-5 text-center">
          <h1>Videos</h1>
          <div className="p">
            There's no replacement for live music... but videos are a close
            second! <br></br>The videos here are all 100% live and uneditted, so
            this is exactly what you can expect at a live performance.
          </div>
        </div>

        <div className="col-lg-8">
          <div className="ratio ratio-16x9">
            <iframe
              src={`https://www.youtube.com/embed/${selectedVideo?.contentDetails.videoId}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              width={"100%"}
            ></iframe>
          </div>
        </div>

        <div
          className="col-lg-4 d-flex flex-column"
          style={{ maxHeight: "480px" }}
        >
          <div className="flex-grow-1 overflow-auto">
            <ul className="list-group list-group-flush">
              {playlist?.items.map((video) => (
                <li
                  className="list-group-item d-flex flex-row align-items-center px-0"
                  key={video.id}
                  data-testid={video.id}
                  onClick={() => {
                    setSelectedVideo(video);
                  }}
                >
                  <div className="col-3">
                    {video.snippet.thumbnails.default && (
                      <img
                        className="w-100"
                        src={video.snippet.thumbnails.default.url}
                      />
                    )}
                  </div>
                  <div className="col-9">
                    <p className="ps-3">{video.snippet.title}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};
