import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import allPhotos from "../images/photosPage/allPhotos";

export const PhotosPage = () => {
  const newImageList = allPhotos;

  useEffect(() => {
    // Function to initialize Masonry layout
    const initMasonry = () => {
      const grid = document.querySelector(".masonry-grid");
      if (grid) {
        new Masonry(grid, {
          itemSelector: ".grid-item",
          percentPosition: true,
        });
      }
    };

    // Wait for all images to load
    const imagesLoadedHandler = imagesLoaded(".masonry-grid");
    imagesLoadedHandler.on("always", () => {
      // Once all images are loaded, initialize the Masonry layout
      initMasonry();
    });

    // Clean up event listeners when the component unmounts
    return () => {
      imagesLoadedHandler.off("always", () => {});
    };
  }, [newImageList]); // Run this effect whenever newImageList changes

  return (
    <>
      <Helmet>
        <script
          src="https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js"
          integrity="sha384-GNFwBvfVxBkLMJpYMOABq3c+d3KnQxudP/mGPkzpZSTYykLBNsZEnG2D9G/X/+7D"
          crossOrigin="anonymous"
          async
        ></script>
      </Helmet>
      <div className="container">
        <div className="mt-5 mb-5 text-center">
          <h1 className="">Photos</h1>
          <p>
            A photo says a thousand words! Here's what you <i>might</i> expect
            to see at your wedding!
          </p>
        </div>
        <div className="row g-3 masonry-grid">
          {newImageList.map((image, index) => {
            return (
              <div className="col-12 col-md-6 col-lg-4 grid-item" key={index}>
                <div className="card shadow zoom">
                  <img src={image} loading="lazy" className="image" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
